<template>
    <div class="w-full p-4 sm:px-5" :class="maxWidthClass">
        <div class="text-center">
            <slot name="logo" />
            <div class="mt-4 pb-2">
                <h2
                    class="text-2xl font-semibold text-white"
                >
                    <slot name="headerTitle" />
                </h2>
                <p class="text-white">
                    <slot name="headerSubtitle" />
                </p>
            </div>
        </div>
        <div class="card mt-5 rounded-default p-5">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        maxWidthClass: {
            type: String,
            default: 'max-w-[26rem]',
        },
    },
};
</script>
